import { withParams, regex, ref } from "vuelidate/lib/validators/common.js"
export const phone = regex('phoneNumber', /^(13[0-9]|14[5|7]|15[0|1|2|3|5|6|7|8|9]|16[0-9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/);
export const password = regex('password', /^[0-9A-Za-z]{6,21}$/);
export const numberic = regex("numberic",/^[0-9]+(.[0-9]{1,2})?$/);
export const intnumber = regex("intnumber",/^[0-9]$g/);
export const loginid = regex("loginid",/^[a-zA-Z]{1}[-_a-zA-Z0-9]{5,19}$|^(13[0-9]|14[5|7]|15[0|1|2|3|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$|^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/);
export const wx = regex("wx",/^[a-zA-Z]{1}[-_a-zA-Z0-9]{5,19}$/);
export const qq = regex("qq",/^[1-9]\d{4,9}$/);
export const website = regex("website",/[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/);
export function gte(equalTo) {
  return withParams(
    {type: 'gte', eq: equalTo},
    function (value, parentVm) {
      return value >= ref(equalTo, this, parentVm)
    }
  )
}
