<template>
  <div class="login">
    <div class="table">
      <div class="table-cell">
        <div class="inline-block">
          <h2>雪芭比后台管理系统</h2>
          <el-form status-icon label-width="60px">
            <el-form-item label="帐号" :error="$v.account.$error ? '请输入帐号!' : ''">
              <el-input type="text" v-model="account"></el-input>
            </el-form-item>
            <el-form-item label="密码" :error="$v.passowrd.$error ? '请输入密码!' : ''">
              <el-input type="password" v-model="passowrd"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="login">登录</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { phone } from "@/utils/myValidate";
import { sendSMSRequest, loginRequest } from "@/api/user";
export default {
  name: "Login",
  data() {
    return {
      account: "",
      passowrd: "",
      required: "",
    };
  },
  methods: {
    login() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        loginRequest({
          Account: this.account,
          Pwd: this.passowrd
        }).then(res => {
          localStorage.setItem("token", res.data.access_token);
          this.$router.push({
            path: "/"
          });
          setTimeout(() => {
            this.$message.success("登录成功");
          }, 500);
        });
      }
    }
  },
  validations: {
    account: {
      required,
    },
    passowrd: {
      required
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/vars.less";

.login {
  width: 100%;
  height: 100%;
  background-color: #eeeeee;
  text-align: center;

  h2 {
    color: white;
    font-size: 30px;
    margin-bottom: 20px;
  }

  button {
    width: 100%;
    background-color: darken(@primary, 10%);
  }

  .captcha-btn {
    width: 120px;
    background: transparent;

    &[disabled="disabled"] {
      color: #c0c4cc !important;
    }
  }

  .table {
    width: 100%;
    height: 100%;
    display: table;

    .table-cell {
      display: table-cell;
      width: 100%;
      height: 100%;
      vertical-align: middle;

      .inline-block {
        display: inline-block;
        width: 500px;
        margin: auto;
        background-color: @primary;
        border-radius: 10px;
        padding: 20px 60px 30px;
        box-shadow: 0 0 10px 0 grey;

        &/deep/label {
          color: white;
        }
      }
    }
  }
}
</style>
